import Footer from '../footer';
import './index.css'
const Terms = (): JSX.Element => {
  return (
    <>
      <div id="pcontainer">
        <h1>Terms of Service</h1>
        <p>February 6th, 2023</p><br />

        <h1>1. Acceptance of the Terms of Use</h1>
        <div className="section">
          <p>
            The Popcoin Studio ("Service") is a hosted service operated by our company( "We", "Us", "Popcoin Studio"). Any use of the Service is subject to the following Terms and Conditions of Use ("Popcoin Studio Terms of Use"), as well as to Popcoin Studio's <a href="/privacy_policy" id="regles" target={'_blank'}>Privacy Policy</a>, all of which are incorporated by reference into these Terms and Conditions. Your use of the Service will constitute your acceptance of these terms and conditions.
          </p>
          <br />
          <p>
            You should carefully read the following Terms of Service Agreement (“Agreement” or “Terms of Service”) before using any website (“Site”) and mobile game/application and services  (collectively the “Services”) of  Popcoin Studio (“Company”).
          </p>
        </div>
        <br />
        <h1>2. Children</h1>
        <div className="section">
          <p>
            Our Services are not designed for Children, and we do not intentionally or knowingly collect, use, store, disclose, or otherwise process any Personal Information from Children. A Child is a person under 18 years old. If you are a parent or guardian of a child who has submitted Personal Information, please contact us by emailing us at support@popcoin.xyz
          </p>
        </div>
        <br />
        <h1>3. Privacy Policy</h1>
        <div className="section">
          <p>
            All personal information we collect on the Websites, through the Services, and through the Games is subject to our Privacy Policy. Please let us know if you have any questions about our practices concerning the collection and processing of your personal information.
          </p>
        </div>
        <br />
        <h1>4. Intellectual Property Rights</h1>
        <div className="section">
          <p>
            The Site, Content and Company’s proprietary assets and any and all intellectual property rights pertaining thereto, including, but not limited to, inventions, patents and patent applications, trademarks, trade names, service marks, copyrightable materials, domain names, and trade secrets, whether or not registered or capable of being registered (collectively, “<strong>Intellectual Property</strong>”), are owned by and/or licensed to the Company and are protected by applicable patent, copyright and other intellectual property laws and international conventions and treaties. All rights not expressly granted to you hereunder are reserved by the Company and its licensors.
          </p>
          <br />
          <p>
            The Terms do not convey to you an interest in or to the Company’s Intellectual Property but only a limited revocable right of use in accordance with the Terms. Nothing in the Terms constitutes a waiver of the Company’s Intellectual Property under any law.
          </p>
          <br />
          <p>
            To the extent you provide any feedback, comments or suggestions to the Company (“<strong>Feedback</strong>”), the Company shall have an exclusive, royalty-free, fully paid up, worldwide, perpetual and irrevocable license to incorporate the Feedback into any of our current or future products, technologies or services and use same for any purpose, all without further compensation to you and without your approval. You agree that all such Feedback shall be deemed to be non-confidential.
          </p>
          <br />
          <p>
            Further, you warrant that your Feedback is not subject to any license terms that would purport to require us to comply with any additional obligations with respect to any of our current or future products, technologies or services that incorporate any Feedback.
          </p>
        </div>
        <br />

        <h1>5. Limited License to Use the Services</h1>
        <div className="section">
          <p>
            These Terms of Use permit you to use the Services and view and use the content contained in the Services for your personal, non-commercial use only. Additionally, your use of such Games may be subject to an end-user license agreement or other written terms (including, without limitation, payment obligations) (“EULA”). In the event of any conflict or inconsistency between the terms of any such EULA and these Terms of Use, such EULA will control. As to each Game, the license contemplated by this paragraph gives you a non-exclusive, non-transferable, non-sublicensable limited right and license to use one copy of the Game for your personal, non-commercial use for gameplay. For clarity, the foregoing license with respect to each Game is only granted to those persons that have purchased from or otherwise been given access to the particular Game by us or one of our third-party distributors, subject to any applicable EULA and other applicable obligations. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material included in the Services. You must not access or use any part of the Services or materials available through them for any commercial purposes. No right, title, or interest in or to the Services or any content thereof is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Services not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
          </p>
        </div>
        <br />
        <h1>6. Feedback</h1>
        <div className="section">
          <p>
            You may elect to informally provide us with Feedback from time to time. You agree that the Company will own, and you hereby assign to the Company your intellectual property rights in and to, any and all Feedback and that the Company will be free to use, disclose, reproduce, license, distribute, and exploit the Feedback provided to it, royalty-free, entirely without obligation or restriction of any kind on account of intellectual property rights or otherwise. “Feedback” means any feedback or ideas you provide to the Company regarding the Services, including the Games, or any suggested improvements.
          </p>
        </div>
        <br />

        <h1>7. Bitcoin and Ethereum</h1>
        <div className="section">
          <p>
            Users of the Game may earn Bitcoin and/or Ethereum (collectively, “Cryptocurrencies”) while playing the Game. We will credit your account for such Cryptocurrencies earned by you, and in our sole discretion, may impose limits on such Cryptocurrencies, including, without limitation, the amount that may be acquired, earned, or transferred. We make no guarantee as to the nature, quality, or value of any Cryptocurrencies or their availability or supply. You acknowledge and agree that we may engage in actions that may impact the perceived value or acquired price of such Cryptocurrencies at any time, except as prohibited by applicable law.
          </p>
          <br />
          <p>
            You are solely responsible for, and will file, on a timely basis, all tax returns and payments required to be filed with, or made to, any applicable tax authority with respect to Cryptocurrencies earned while playing the Game.
          </p>
          <br />
          <p>
            Users of the Game may also transfer the Cryptocurrencies they earn by playing the Game to their cryptocurrency exchange accounts based upon an exchange rate and other requirements, procedures, and limitations established by the cryptocurrency exchanges. You agree to abide by cryptocurrency exchange platform requirements. All transfers of cryptocurrency are final and not refundable or exchangeable, except as required by applicable law or as provided by cryptocurrency exchanges. We make no guarantee as to your ability to access your Uphold account or make a Transfer in cryptocurrency exchange, and we will have no liability for any losses you may incur as a result of your inability to access your cryptocurrency exchange account or make a Transfer.
          </p>
        </div>
        <br />
        <h1>8. Prohibited Uses</h1>
        <div className="section">
          <p>
            <span>
              You may use the Services only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Services in any way that violates applicable laws, to exploit or harm anyone, to send advertising or promotional material, or to impersonate or attempt to impersonate the Company or anyone else. Additionally, you agree not to:
            </span> <br /><br />
            a) disable, overburden, damage, or impair (or attempt to disable, overburden, damage, or impair) the Services or interfere with anyone else's use of the Services; <br /><br />
            b) engage in any other conduct that affects anyone else's use or enjoyment of the Services or that, as determined by us, may harm the Company;<br /><br />
            c) cheat, including, without limitation, by accessing the Games in an unauthorized manner, playing with multiple accounts for the same Game, sharing accounts, using any techniques to alter or falsify a device's location (for example, through GPS spoofing), and selling or trading accounts;<br /><br />
            d) use the Services to violate the legal rights of others, including, without limitation, their privacy, publicity, and intellectual property rights;<br /><br />
            e) remove any copyright, trademark, or other proprietary rights notices contained in or on the Services;<br /><br />
            f) use any robot, spider, or other automatic devices, process, or means to access the Services for any purpose, including monitoring or copying any of the material on the Services;<br /><br />
            g) modify, adapt, translate, or reverse engineer any portion of the Services, or use any manual process to monitor or copy any of the material on the Services for any other unauthorized purpose;<br /><br />
            h) use any device, software, or routine that interferes with the proper working of the Services;<br /><br />
            i) introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful;<br /><br />
            j) attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Services, including any server on which the Services are stored, or any server, computer, or database connected to the Services;<br /><br />
            k) attack the Services via a denial-of-service attack or a distributed denial-of-service attack;<br /><br />
            l) otherwise attempt to interfere with the proper working of the Services; and <br /><br />
            m) encourage or enable any other person to do any of the foregoing.<br /><br />
          </p>
        </div>
        <br />
        <h1>9. Additional Prohibited Uses For the Games</h1>
        <div className="section">
          <p>
            While you are using the Games, please be aware of your surroundings, and play and communicate safely. You agree that your use of the Games is at your own risk and that you will not use the Games to violate any applicable law, regulation, or instructions as outlined in these Terms of Use and you will not encourage or enable any other individual to do so.
          </p>
          <br />
          <p>
            You agree that in conjunction with your use of the Games, you will maintain safe and appropriate contact with other users and other people in the real world. You will not harass, threaten or otherwise violate the legal rights of others. You will not trespass, or in any manner attempt to gain or gain access to any property or location where you do not have a right or permission to be, and will not otherwise engage in any activity that may result in injury, death, property damage, nuisance, or liability of any kind.

          </p>
        </div>
        <br />
        <h1>10. Reliance on Information Posted</h1>
        <div className="section">
          <p>
            We do not warrant the accuracy, completeness, or usefulness of any information made available through the Services. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other user of the Services, or by anyone who may be informed of any of the contents of the Services.
          </p>
        </div>
        <br />
        <h1>11. Linking to the Websites and Social Media Features</h1>
        <div className="section">
          <p>
            You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part.
          </p>
        </div>
        <br />
        <h1>12. Links from the Websites</h1>
        <div className="section">
          <p>
            If the Websites contain links to other sites and resources provided by third parties, these links are provided for your convenience only. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.
          </p>
        </div>
        <br />
        <h1>13. Changes to Websites and Unavailability of Websites</h1>
        <div className="section">
          <p>
            We reserve the right to withdraw or amend the Websites at our sole discretion without notice. The Websites and their content are not necessarily always complete or up-to-date, and we are under no obligation to update them. All or any part of the Websites is unavailable at any time or for any period.
          </p>
        </div>
        <br />
        <h1>14. Termination</h1>
        <p>
          At any time, we may without notice discontinue your use of the Site in addition to any other remedies that may be available to us under applicable law.
        </p>
        <br />
        <div className="section">
          <p>
            Additionally, we may at any time, at our discretion, cease the operation of the Site or any part thereof, temporarily or permanently, delete any information or Content from the Site or correct, modify, enhance and make other changes thereto or discontinue displaying or providing any information, Content or features therein without prior notice. You agree that the Company does not assume any responsibility with respect to, or in connection with the termination of the Website's operation and loss of any data. The provisions of these terms that by their nature and content must survive termination hereof to achieve their fundamental purposes shall so survive. Without limiting the generality of the forgoing, the Intellectual Property, Disclaimer and Warranties, Limitation of Liability, Indemnification and General sections will survive the termination of the Terms.
          </p>
        </div>
        <br />
        <h1>15. DISCLAIMER OF WARRANTIES</h1>
        <div className="section">
          <p>
            YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, AND PRODUCTS (INCLUDING THE GAMES AND OTHER SOFTWARE) INCLUDED IN OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS-IS” AND “AS-AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THE INFORMATION, CONTENT, MATERIALS, AND PRODUCTS (INCLUDING THE GAMES AND SOFTWARE) OF THE SERVICES, OR ELECTRONIC COMMUNICATIONS SENT FROM THE COMPANY WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE TO YOU ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </p>
          <br />
          <p>
            YOU ASSUME ALL RISKS RELATING TO YOUR ONLINE OR OFFLINE COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES. YOU UNDERSTAND THAT THE COMPANY DOES NOT SCREEN OR INQUIRE INTO THE BACKGROUND OF ANY USERS OF THE SERVICES. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE SERVICES. YOU AGREE TO TAKE REASONABLE PRECAUTIONS IN ALL COMMUNICATIONS AND INTERACTIONS WITH OTHER USERS OF THE SERVICES AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES, PARTICULARLY IF YOU DECIDE TO MEET OFFLINE OR IN PERSON.
          </p>
          <br />
          <p>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </div>
        <br />
        <h1>16. LIMITATION OF LIABILITY</h1>
        <div className="section">
          <p>
            TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL THE Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH (A) THESE TERMS OF USE, (B) THE USE OF OR INABILITY TO USE THE SERVICES OR THEIR CONTENT, OR (C) ANY COMMUNICATIONS, INTERACTIONS, OR MEETINGS WITH OTHER USERS OF THE SERVICES OR PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE SERVICES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, WITHOUT LIMITATION, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
          </p>
          <br />
          <p>
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>
        </div>
        <br />
        <h1>17. Indemnification</h1>
        <div className="section">
          <p>
            You agree to defend, indemnify and hold harmless the Company, including Company’s Representatives from and against any and all claims, damages, obligations, losses, liabilities, costs, debts, and expenses (including but not limited to attorney’s fees) arising from: (i) your use, misuse of, inability to use and/or activities in connection with the Site and/or Content; (ii) your violation of any of these Terms; (iii) your violation of any third party rights, including without limitation any intellectual property rights or privacy right of a such third party; and (iv) any damage of any sort, whether direct, indirect, special or consequential, you may cause to any third party with relation to the Site. It is hereby clarified that this defense and indemnification obligation will survive these Terms.
          </p>
          <br />
          <p>
            Without derogating from the foregoing, we reserve the right to assume the exclusive defense and control of any matter which is subject to indemnification by you, which will not excuse your indemnity obligations hereunder and in which event you will fully cooperate with us in asserting any available defense. You agree not to settle any matter subject to an indemnification by you without first obtaining our prior express written approval.
          </p>
        </div>
        <br />
        <h1>18. Waiver and Severability</h1>
        <div className="section">
          <p>
            No waiver by the Company of any term or condition set out in these Terms of Use will be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use will not constitute a waiver of such right or provision. If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision will be eliminated or limited to the minimum extent such that the remaining provisions of these Terms of Use will continue in full force and effect.
          </p>

        </div>
        <br />
        <h1>19. Entire Agreement</h1>
        <div className="section">
          <p>
            These Terms of Use and our Privacy Policy are the sole and entire agreement between you and the Company regarding the Services and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding their subject matter.
          </p>

        </div>
        <br />
        <h1>20. Changes to these Terms of Use</h1>
        <div className="section">
          <p>
            We may revise and update these Terms of Use from time to time at our sole discretion. All changes are effective immediately when we post them. If you continue to use our Services, following the posting of revised Terms of Use, you are signifying that you accept and agree to the changes. You are expected to check this page from time to time, so you are aware of any changes.
          </p>

        </div>
        <br />
        <h1>21. Your Comments and Concerns</h1>
        <div className="section">
          <p>
            If you have any questions about the Services or these Terms of Use, please contact us at <a href="mailto:support@popcoin.xyz">support@popcoin.xyz</a>
          </p>

        </div>
        <br />
      </div>
      <Footer></Footer>

    </>
  );
}

export default Terms;