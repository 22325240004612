const Footer = (): JSX.Element => {
  return (
    <>
      <div id="footer">
        {/* <a href="/">Download</a> */}
        <a href="/terms" target="blank">Terms of use</a>
        <a href="privacy_policy" target="blank">Privacy Policy</a>
        {/* <a href="/guidelines" target="blank">Community Guidelines</a>
        <a href="/safety" target="blank">Safety</a>
        <a href="/legal-notice" target="blank">Legal Notice</a> */}
      </div>
    </>
  );
}

export default Footer;