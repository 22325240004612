import Footer from '../footer';
import './index.css'
const PrivacyPolicy = (): JSX.Element => {
  return (
    <>
      <div id="pcontainer">
        <h1>Privacy Policy</h1>
        <p>February 6th, 2023</p><br />
        {/* <h1>Welcome!</h1> */}
        <div className="section">
          <p>
            This Privacy Policy describes the policies and procedures on the collection, use and disclosure of your information when you use the service and tells you about your privacy rights and how the law protects you.
          </p>
          <br />
          <p>
            Popcoin Studio uses your personal data to provide and improve the service. BY DOWNLOADING, INSTALLING ANY Popcoin Studio APPLICATION (THE “APPLICATION”) AND USING IT IN CONNECTION WITH OTHER SERVICES PROVIDED BY Popcoin Studio OR OTHERWISE ACCESSED THROUGH THE USE OF THE APPLICATION (SUCH SERVICES AND THE APPLICATION COLLECTIVELY, THE “SERVICE”) YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, ACCEPT AND AGREE TO BE BOUND BY THESE TERMS.
          </p>
        </div>
        <br />
        <h1>Definitions</h1>
        <div className="section">
          <p>
            <span>
              For the purposes of this Privacy Policy:
            </span><br /><br />
            1. You means the individual accessing or using the service, or the company, or other legal entity on behalf of which such individual is accessing or using the service, as applicable.<br /><br />
            2. Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to "Popcoin Studio".<br /><br />
            3. Account means a unique account created for you to access our service or parts of our service.<br /><br />
            4. Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the service, to provide the service on behalf of the Company, to perform services related to the service or to assist the Company in analyzing how the service is used.<br /><br />
            5. Third-party Social Media Service refers to any website or any social network website through which a user can log in or create an account to use the service.<br /><br />
            6. Personal Data is any information that relates to an identified or identifiable individual.<br /><br />
            7. Cookies are small files that are placed on your computer, mobile device or any other device by a website, containing the details of your browsing history on that website among its many uses.<br /><br />
            8. Device means any device that can access the service such as a computer, a cellphone or a digital tablet.<br /><br />
            9. Usage Data refers to data collected automatically, either generated by the use of the service or from the service infrastructure itself (for example, the duration of a page visit).<br /><br />
          </p>
        </div>
        <br />
        <h1>Collecting and Using Your Personal Data</h1>
        <div className="section">
          <h1>Types of Data Collected</h1>
          <p>
            Personal Data
          </p>
          <br />
          <p>
            While using the service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
            <br /><br />
            1. Email address<br /><br />
            2. User name<br /><br />
            3. Usage Data<br /><br />
          </p>
          <br />
          <p>
            Usage Data
          </p>
          <br />
          <p>
            Usage Data is collected automatically when using the service.
          </p>
          <br />
          <p>
            Usage Data may include information such as your device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of the service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
          </p>
          <br />
          <p>
            When you access the service by or through a mobile device, Popcoin Studio may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
          </p>
          <br />
          <p>
            Popcoin Studio may also collect information that your browser sends whenever you visit our service or when you access the service by or through a mobile device.
          </p>
          <br />
          <div className="section">
            <h1>Tracking Technologies and Cookies</h1>
            <p>
              Popcoin Studio uses cookies and similar tracking technologies to track activity on the service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze the service.
            </p><br />
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our service.
            </p><br />
            <p>
              Cookies can be persistent or session cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your web browser.
            </p><br />
            <p>
              Popcoin Studio uses both session and persistent cookies. Cookies are essential to provide you with services available through the website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these cookies, the services that you have asked for cannot be provided, and Popcoin Studio only uses these cookies to provide you with those services.
            </p>
          </div>
          <div className="section">
            <h1>Use of Your Personal Data</h1>
            <p>
              Popcoin Studio may use Personal Data for the following purposes:<br /><br />
              1. To provide and maintain the service, including to monitor the usage of our service.<br /><br />
              2. To manage your account: to manage your registration as a user of the service. The Personal Data you provide can give you access to different functionalities of the service that are available to you as a registered user.<br /><br />
              3. For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services you have purchased or of any other contract with Popcoin Studio through the service.<br /><br />
              4. To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.<br /><br />
              5. To attend and manage your requests.<br /><br />
            </p>
            <p>
              We may share your personal information in the following situations: <br /><br />
              1. With service providers: We may share your personal information with service providers to monitor and analyze the use of our service.<br /><br />
              2. For Business transfers: We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Popcoin Studio assets, financing, or acquisition of all or a portion of our business to another company.<br /><br />
              3. With Affiliates: We may share your information with the affiliates. Affiliates include our parent company and any other subsidiaries, joint venture partners or other companies that we control or that are in business relation with us.<br /><br />
              4. With Business partners: We may share your information with our business partners.<br /><br />
              5. With other users: when you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If you interact with other users or register through a Third-Party Social Media Service, your contacts on the Third-Party Social Media Service may see your profile, and description of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you and view your profile.<br /><br />
            </p>
            <p>
              <span>Disclosure of Your Personal Data</span><br /><br />
              Business Transactions<br /><br />
              If the Company is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.<br /><br />
            </p>
            <p>
              <span>Law enforcement</span><br /><br />
              Under certain circumstances, the Company may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).<br /><br />
            </p>
            <p>
              <span> Other legal requirements</span><br /><br />
              The Company may disclose your Personal Data in the good faith belief that such action is necessary to:<br /><br />
              1. Comply with a legal obligation<br /><br />
              2. Protect and defend the rights or property of the Company<br /><br />
              3. Prevent or investigate possible wrongdoing in connection with the service<br /><br />
              4. Protect the personal safety of users of the service or the public<br /><br />
              5. Protect against legal liability<br /><br />
            </p>
            <p>
              <span>Security of Your Personal Data</span><br /><br />
              The security of Your Personal Data is important but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While Popcoin Studio strives to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.<br /><br />
            </p>
            <p>
              <span>Use of Services By Children</span><br /><br />
              Our Service does not address anyone under the age of 18. We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided Popcoin Studio with Personal Data, please contact us. If  Popcoin becomes aware that we have collected Personal Data from anyone under the age of 18 without verification of parental consent, we take steps to remove that information from the servers.<br /><br />
              If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.<br /><br />
            </p>
            <p>
              <span>Links to Other Websites</span><br /><br />
              Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third-party's site. We strongly advise you to review the Privacy Policy of every site you visit.<br /><br />
              We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.<br /><br />
            </p>
            <p>
              <span>Changes to this Privacy Policy</span><br /><br />
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.<br /><br />
              We will let you know via a prominent notice on the service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.<br /><br />
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.<br /><br />
            </p>
            <p>
              <span>Contact Us</span><br /><br />
              If you have any questions about this Privacy Policy, you can contact us: <a id='email' href="mailto:support@popcoinstudio.xyz">support@popcoinstudio.xyz</a>
            </p>
          </div>
        </div>
        <br />
      </div>
      <Footer></Footer>

    </>
  );
}

export default PrivacyPolicy;