import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Aos from 'aos';
import 'normalize.css';
import './style/index.scss';
import 'aos/dist/aos.css'

window.onload = () => {
  setTimeout(() => {
    window.scrollTo(0, 0);
    Aos.init({
      mirror: true,
      easing: "ease",
      once: true,
      duration: 1000,
      anchorPlacement: 'center-center',
      offset: -500
    });
  }, 200)
}

if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.error = () => { };
  console.warn = () => { };
}



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
