import { useEffect, useState } from 'react';
import { isMobileSize } from '../../libs/userAgent';
import './index.scss'

const Header = (): JSX.Element => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const [winScrollTop, setScrollTop] = useState(0)

  const [ActiveNav, setActiveNav] = useState()
  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const handleScroll = () => {
    setScrollTop(window.scrollY)

    const navItems: any = document.querySelectorAll('.nav-item');

    for (let i = navItems.length - 1; i >= 0; i--) {
      const navItem = navItems[i];
      // @ts-ignore
      const section = document.getElementById(navItem.dataset.to).getBoundingClientRect();
      if (section.top <= 10) {
        setActiveNav(navItem.getAttribute('data-to'));
        break;
      }
    }
  }



  const goToPage = (to: string) => {
    const dom = document.getElementById(to);
    if (dom) {
      dom.scrollIntoView({ behavior: "smooth", });
    }
  }
  return (
    <>
      <div className={['header', winScrollTop > 0 ? 'shadow' : ''].join(' ')}>
        <div className="container">
          <img className='logo' src={require('../../assets/img/logo.png').default} alt="" />
          {
            isMobileSize()
              ? <div
                className={['KebabMenuContainer', mobileOpen ? 'open' : 'close'].join(' ')}
                onClick={() => {
                  setMobileOpen(!mobileOpen)
                }}
              >
                <div className="KebabLine Line1"></div>
                <div className="KebabLine Line2"></div>
                <div className="KebabLine Line3"></div>
              </div>
              : <div className="nav">
                <div
                  className={['item', 'nav-item', ActiveNav === 'Home' ? 'active' : ''].join(' ')}
                  data-to={'Home'}
                  onClick={() => { goToPage('Home') }}
                >Home</div>
                <div
                  className={['item', 'nav-item', ActiveNav === 'Product' ? 'active' : ''].join(' ')}
                  data-to={'Product'}
                  onClick={() => { goToPage('Product') }}
                >Products</div>
              </div>
          }
          {
            isMobileSize()
              ? <div className={['MobileMainMenuContainer', mobileOpen ? 'open' : 'close'].join(' ')}>
                <img className='logo' src={require('../../assets/img/logo.png').default} alt="" />
                <div className="MenuMobileLinkContainer">
                  <div
                    className={['MenuMobileLink', 'nav-item', ActiveNav === 'Home' ? 'active' : ''].join(' ')}
                    data-to={'Home'}
                    onClick={() => {
                      goToPage('Home')
                      setMobileOpen(!mobileOpen)
                    }}
                  >
                    Home
                  </div>
                  <div
                    className={['MenuMobileLink', 'nav-item', ActiveNav === 'Product' ? 'active' : ''].join(' ')}
                    data-to={'Product'}
                    onClick={() => {
                      goToPage('Product')
                      setMobileOpen(!mobileOpen)
                    }}
                  >
                    Products
                  </div>
                </div>
              </div>
              : ''
          }

        </div>
      </div>
    </>
  );
}

export default Header;