export const isMobile = (): boolean => {
  let isMobile: boolean = false;

  const _isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  const WWidth = window.innerWidth;

  if (_isMobile && WWidth < 960) {
    isMobile = true;
  }

  return isMobile;
};

export const isMobileSize = (): boolean => {
  const WWidth = window.innerWidth;
  if (WWidth < 960) {
    return true;
  }

  return false;
};
