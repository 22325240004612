import React, { useRef, useState } from 'react';

import './index.scss'
const VideoPlayer = (props: { cover: string, video: string, player: boolean, poster: string }): JSX.Element => {
  const [mousePos, setMousePos] = useState({ x: '50%', y: '50%' });
  const [modelShow, setModelShow] = useState(false)
  const Container = useRef<HTMLDivElement>(null)
  const VideoDOM = useRef<HTMLVideoElement>(null)
  const handleMouseMove = (event: any) => {
    event.preventDefault();
    if (Container.current) {
      const { left, top } = Container.current.getBoundingClientRect()

      var x = event.clientX - left;
      var y = event.clientY - top;
      setMousePos({ x: `${x}px`, y: `${y}px` });
    }

  };

  const handleMouseLeave = (event: any) => {
    event.preventDefault();
    setMousePos({ x: '50%', y: '50%' });
  };
  return (
    <>
      <div className={['VideoPlayer', props.player ? 'no-cursor' : ''].join(' ')}
        ref={Container}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (!props.player) return
          setModelShow(true)
          if (VideoDOM.current) {
            VideoDOM.current.currentTime = 0;
            VideoDOM.current.play();
          }
        }}
      >
        <div className="VideoPlayerContainer" style={{ backgroundImage: `url(${props.cover})` }}>
          {
            props.player
              ? <div
                className="PlayButton"
                style={{ left: mousePos.x, top: mousePos.y }} >
              </div>
              : ''
          }

        </div>
      </div>
      <div className={['video_model', modelShow ? 'open' : 'close'].join(' ')} >
        <div className="bg" onClick={() => { setModelShow(false) }}></div>
        <div className="model_container">
          <div className="VideoWindowCloseButton" onClick={() => { setModelShow(false) }}>
            <div className="IconContainer">
              <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 24 24" version="1.1" xmlSpace="preserve" style={{
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                strokeMiterlimit: 10
              }} className="injected-svg">
                <g>
                  <path d="M14.86,14.86l-5.72,-5.72l5.72,5.72Z" style={{
                    fill: 'none',
                    fillRule: 'nonzero',
                    strokeWidth: '1.91px'
                  }}></path>
                  <path d="M14.86,9.14l-5.72,5.72l5.72,-5.72Z" style={{
                    fill: 'none',
                    fillRule: 'nonzero',
                    strokeWidth: '1.91px'
                  }}></path>
                </g>
              </svg>
            </div>
          </div>
          <div className="video_con">
            <video ref={VideoDOM} src={props.video} muted autoPlay loop controls poster={props.poster}></video>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPlayer;