import Header from "../../components/header";
import VideoPlayer from "../picBox";
import "./index.scss";

const Home = (): JSX.Element => {
  return (
    <>
      <div className="home" id="Home">
        <Header></Header>
        <div className="main">
          <div className="container">
            <div
              className="section1"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="150"
            >
              <div className="main-row">
                <div className="left">
                  <h2 className="title">
                    The <u>Fun</u> and easy <br className="mobile_hide" />
                    Gateway to Web3
                  </h2>
                  <p className="des">
                    Empower everyone with no prior <br />
                    experience to access, enjoy, and settle in web3.
                  </p>
                </div>
                <div className="right">
                  <div className="video_box">
                    <VideoPlayer
                      cover={require('../../assets/img/pj_cover.png').default}
                      video={''}
                      player={false}
                      poster={''}
                    ></VideoPlayer>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section2"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="300"
            >
              <div className="main-row">
                <div className="left">
                </div>
                <div className="right">
                  <h2 className="title">
                    Onboard the next <br className="mobile_hide" />
                    billion users to <u>web3</u>
                  </h2>
                  <p className="des">
                    Leading billions of users to embrace the innovation of Web3 and <br className="mobile_hide" /> speeding up the building of a Web3 world that’s truly their own.
                  </p>
                  <div className="BoxListContainer">
                    <div
                      className="BoxListItem"
                      data-aos="flip-right"
                      data-aos-duration="500"
                    >
                      <div className="title">300K+</div>
                      <div className="text">MAU</div>
                    </div>
                    <div
                      className="BoxListItem"
                      data-aos="flip-right"
                      data-aos-duration="500"
                    >
                      <div className="title">30+</div>
                      <div className="text">COUNTRIES</div>
                    </div>
                    <div
                      className="BoxListItem"
                      data-aos="flip-right"
                      data-aos-duration="500"
                    >
                      <div className="title">600K</div>
                      <div className="text">Total Web3 Users</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="spec" id="Product"></div>
            <div
              className="section3"
              data-aos="fade-up"
              data-aos-duration="600"
              data-aos-delay="450"
            >
              <div className="main-row">
                <div className="left">
                  <h2 className="title">
                    <u>Product</u> Matrix
                  </h2>
                  <p className="des">
                    We are launching a wide range of products to migrate <br className="mobile_hide" />
                    Web2 users to Web3 in an effortless and effective way.
                  </p>
                </div>
              </div>
            </div>
            <div className="spec" id="Product"></div>
            <div
              className="section4"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <div className="main-row">
                <div className="left">
                  <div className="video_box">
                    <VideoPlayer
                      cover={require('../../assets/img/witcoin.png').default}
                      video={require('../../assets/img/Witcion.mp4').default}
                      player={true}
                      poster={require('../../assets/img/witcoin_poster.jpg').default}
                    ></VideoPlayer>
                  </div>
                </div>
                <div className="right">
                  <h3 className="Heading">
                    Witcoin
                    <div className="GameContainerFreeBadge">
                      FREE
                    </div>
                  </h3>
                  <h6 className="subtitle">
                    Learn To Earn
                  </h6>
                  <p className="des">
                    Acquire educational Web3 experience <br className="mobile_hide" />while earning crypto rewards.
                  </p>
                  <button
                    className="ButtonContainer"
                    onClick={() => {
                      window.open('https://witcoin.space/', '_blank')
                    }}
                  >
                    <div
                      className="ButtonContent"

                    >
                      Get it now!
                    </div>
                    <div className="icon_arrow">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 57 57" version="1.1" xmlSpace="preserve" style={{
                        fillRule: 'evenodd',
                        clipRule: 'evenodd',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 2
                      }} className="injected-svg">
                        <path d="M56.693,28.306l-27.305,27.306l-8.249,-8.169l13.534,-13.292l-34.673,0l0,-11.61l34.673,-0l-13.534,-13.293l8.249,-8.167l27.305,27.225Z"></path></svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="spec"></div>
            <div
              className="section5"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <div className="main-row row-reverse">
                <div className="left">
                  <div className="video_box">
                    <VideoPlayer
                      cover={require('../../assets/img/yeti.png').default}
                      video={require('../../assets/img/yeti.mp4').default}
                      player={true}
                      poster={require('../../assets/img/yeti_poster.jpg').default}
                    ></VideoPlayer>
                  </div>
                </div>
                <div className="right">
                  <h3 className="Heading">
                    Yeti
                    <div className="GameContainerFreeBadge">
                      FREE
                    </div>
                  </h3>
                  <h6 className="subtitle">
                    Chat & Make New Friends
                  </h6>
                  <p className="des">
                    Discover new friendships, chat & <br className="mobile_hide" />monetize your social network.
                  </p>
                  <button
                    className="ButtonContainer"
                    onClick={() => {
                      window.open('https://yeti.social/', '_blank')
                    }}
                  >
                    <div
                      className="ButtonContent"

                    >
                      Get it now!
                    </div>
                    <div
                      className="icon_arrow"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 57 57" version="1.1" xmlSpace="preserve" style={{
                        fillRule: 'evenodd',
                        clipRule: 'evenodd',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 2
                      }} className="injected-svg">
                        <path d="M56.693,28.306l-27.305,27.306l-8.249,-8.169l13.534,-13.292l-34.673,0l0,-11.61l34.673,-0l-13.534,-13.293l8.249,-8.167l27.305,27.225Z"></path></svg>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="spec"></div>
            <div
              className="section6"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              <div className="main-row row-reverse">
                <div className="left">
                  <h2 className="title">
                    More
                  </h2>
                  <p className="des">
                    We plan on launching more SocialFi & <br className="mobile_hide" />GameFi apps that provide users with fun <br className="mobile_hide" />and easy portals to the world of Web3.
                  </p>
                  <button className="ButtonContainer coming">
                    <div className="ButtonContent">
                      Coming soon
                    </div>
                  </button>
                </div>
                <div className="right">
                  <div className="video_box">
                    <VideoPlayer
                      cover={require('../../assets/img/more.png').default}
                      video={''}
                      player={false}
                      poster={''}
                    ></VideoPlayer>
                  </div>
                </div>
              </div>
            </div>
            <div className="spec mobile_hide"></div>
          </div>
        </div>
        <div
          className="footer"
          data-aos="fade-up"
          data-aos-duration="600"
          data-aos-anchor-placement='top-center'
          data-aos-offset='-800'
        >
          <div className="logo">
            <img src={require('../../assets/img/logo.png').default} alt="logo" />
          </div>
          {/* <h6 className="company">西安逆熵科技有限公司</h6> */}
          <ul className="nav">
            <li className="item">
              <a href="/terms" target="blank">
                Terms of use
              </a>
            </li>
            <li className="item">
              <a href="/privacy_policy" target="blank">
                Privacy policy
              </a>
            </li>
          </ul>
        </div>
        <div
          className="bg1"
          data-aos="fade-right"
          data-aos-duration="600"
        >
        </div>
        <div
          className="bg2"
          data-aos="fade-left"
          data-aos-duration="600"
        >
        </div>
        <div
          className="bg3"
          data-aos="fade-up"
          data-aos-duration="600"
        >
        </div>
      </div>
    </>
  );
}

export default Home;
